<template>
    <div>
        <div class="principal">
            <section>
                <form>
                    <center><img src="/img/gesto/alt60px_logo-gesto2.png" style="height: 90px;"></center>
                    <div class="inputbox">
                        <input type="text" required
                            autocomplete="off"
                            v-model="userLogin.rutEmpresa" 
                            @keyup="formatearIngresoRut"
                            @keyup.enter="ingresar"
                        >
                        <label for="">Rut Empresa{{ !userLogin.rutEmpresaValidado?' (Invalido!)':'' }}</label>
                    </div>
                    <div class="inputbox">
                        <input type="text" required
                            autocomplete="off"
                            v-model="userLogin.username"
                            @keyup="formatearNombreUsuario"
                            @keyup.enter="ingresar"
                        >
                        <label for=""> Nombre Usuario</label>
                    </div>
                    <div class="inputbox">
                        <input type="password" required
                            autocomplete="off"
                            v-model="userLogin.passw"
                            @keyup.enter="ingresar">
                        <label for="">Contraseña</label>
                    </div>
                    <div class="forget" v-show="!showMensajeCambioPass && !showMensajeRutNoConfig">
                        <span><label for="" v-if="habilitadoRecordar"><input type="checkbox" v-model="recordarDatos"> {{ textosWeb.textoRecordar }}</label></span>
                        <span><a href="#" v-if="habilitadoOlvideContrasenia" @click="goReestablecerPsw">Olvidé mi contraseña</a></span>
                    </div>
                    
                    <!--<CRow v-show="true">
                      <CCol col="10">
                          <CAlert :color="tipoMensaje" style="max-width:230px">{{textosWeb.msjCambiarPass}}</CAlert>
                      </CCol>
                      <CCol col="2">
                        <CButton class="mt-3" style="background-color:#0b7689;color:white;" @click="cambiarContraseña()">Si!</CButton>
                      </CCol>
                    </CRow>-->
                    <div v-show="showMensajeCambioPass || showMensajeRutNoConfig">
                        <div v-show="showMensajeCambioPass" class="msjs">
                            <span v-show="!enviandoSolicitud">
                                <CIcon
                                    style="color: #f9b115;"
                                    v-tippy="{ 
                                        placement: 'top',
                                        arrow: true, 
                                        arrowType: 'round', 
                                        animation:'fade',
                                        theme: 'google',
                                        followCursor : true,
                                        content: textosWeb.msjCambiarPass
                                    }"
                                    :content="$options.freeSet.cilWarning"
                                />
                                <a href="#" style="color: #f9b115" v-if="showMensajeCambioPass" @click="cambiarContraseña()">Cambiar contraseña Aquí</a>
                            </span>
                            
                            <span v-show="enviandoSolicitud">
                                <CIcon class="imgr"
                                    v-show="!solicitudEnviada"
                                    :content="$options.freeSet.cilLoopCircular"
                                /> {{ textosWeb.msjSolicitandoCambio }}
                            </span>
                        </div>

                        <div v-show="showMensajeRutNoConfig" class="msjs">
                            <div class="inputbox" v-show="ingresarRut">
                                <!--<CInput
                                    :is-valid="rutValidarRegistrar"
                                    @blur="validarRutRegistrar"
                                    placeholder="Rut Usuario"
                                    autocomplete="Rut Usuario"
                                    v-model="rutRegistrar"
                                ></CInput>-->
                                <input type="text" required
                                    :is-valid="rutValidarRegistrar"
                                    autocomplete="off"
                                    v-model="rutRegistrar" 
                                    @keyup="formatearRutRegistrar"
                                >
                                <label for="">Rut Usuario {{ !rutValidarRegistrar?' (Invalido!)':'' }}</label>
                            </div>
                            <div v-show="!ingresarRut">
                                <CIcon style="color: #f9b115"
                                    v-tippy="{ 
                                        placement: 'top',
                                        arrow: true, 
                                        arrowType: 'round', 
                                        animation:'fade',
                                        theme: 'google',
                                        followCursor : true,
                                        content: msjRutNoConfig
                                    }"
                                    :content="$options.freeSet.cilWarning"
                                />
                                <a href="#" style="color: #f9b115" @click="ingresarRut = !ingresarRut">{{(ingresarRut)?'Agregar Luego':'Agregue su Rut aquí'}}</a>
                            </div>
                        </div>
                        <div v-show="ingresarRut && showMensajeRutNoConfig">
                            <CButton block shape="pill" 
                                style="background-color:#29235C; color:white;"
                                class="mb-1"
                                @click="ingresarRut = !ingresarRut">
                                Agregar Luego
                            </CButton>
                        </div>
                        <hr>

                        <!--<CRow v-show="showMensajeCambioPass">
                            <CCol col="10">
                                <CAlert :color="tipoMensaje" style="max-width:230px">{{msjCambiarPass}}</CAlert>
                            </CCol>
                            <CCol col="2">
                                <CButton class="mt-3" style="background-color:#0b7689;color:white;" @click="cambiarContraseña()">Si!</CButton>
                            </CCol>
                        </CRow>

                        <CRow v-show="enviandoSolicitud">
                            <CCol sm="12" md="12" lg="12">
                                <CAlert color="success" style="max-width:300px">{{msjSolicitandoCambio}}</CAlert>
                            </CCol>
                        </CRow>-->
                        
                    
                    <CButton block shape="pill" 
                        class="m-1"
                        style="background-color:#0b7689; color:white; width: 100%;" 
                        :disabled="(ingresarRut && !rutValidarRegistrar) || (enviandoSolicitud && !solicitudEnviada)" 
                        @click="loguearUser">
                        <span v-if="ingresarRut">Registrar e Ingresar</span>
                        <span v-else>Ingresar Portal</span>
                    </CButton>
                        <hr>
                    </div>
                    <!--<div>
                        <CRow v-show="showMensajeRutNoConfig">
                            <CCol col="10">
                                <CAlert :color="tipoMensaje" style="max-width:230px">{{msjRutNoConfig}}</CAlert>
                            </CCol>
                            <CCol col="2">
                                <CButton class="mt-3" style="background-color:#0b7689;color:white;" @click="ingresarRut = !ingresarRut">{{(ingresarRut)?'Luego':'Si!'}}</CButton>
                            </CCol>
                        </CRow>
                        <CRow v-show="ingresarRut">
                            <CCol col="12">
                                <CInput
                                    :is-valid="rutValidarRegistrar"
                                    @blur="validarRutRegistrar"
                                    placeholder="Rut Usuario"
                                    autocomplete="Rut Usuario"
                                    v-model="rutRegistrar"
                                ></CInput>
                            </CCol>
                        </CRow>
                    </div>-->
                    <div v-show="!showMensajeCambioPass && !showMensajeRutNoConfig">
                        <CargandoInfo :mostrarCarga="isLogin" :textColor="'#fff'" :mensajeInfo="'Iniciando Sesión...'" />
                        <!--:img="'/img/gesto/cloud.png'"
                            <div v-show="isLogin">
                            <center>
                                <img src="/img/logo2.png" size="custom-size" :height="40" class="imgr mt-3 mb-3" /> <span style="color: #fff;">Iniciando Sesión...</span>
                            </center>
                        </div>-->
                        <CButton 
                            style="background-color:#0b7689; color:white;"
                            block shape="pill" 
                            class="mt-2 mb-2"
                            v-show="!isLogin"
                            @click="iniciarSesion">
                            Iniciar Sesión
                        </CButton>
                        <hr>
                        <CButton v-show="!isLogin" block shape="pill" style="background-color:#29235C; color:white;" class="mt-2 mb-2" @click="cambioPortal">Ir a portal Trabajador</CButton>
                        
                    </div>
                      
                    <!--<CRow v-if="habilitadoRegistrar">
                        <CCol col="12" class="register">
                            <p>¿No tienes cuenta? <a href="#">Registrar</a></p>
                        </CCol>
                    </CRow>-->
                    <!--<div class="register" v-if="habilitadoRegistrar">
                        <p>¿No tienes cuenta? <a href="#">Registrar</a></p>
                    </div>-->
                    
                    
                    <div class="mt-3 mfs-auto version float-right">
                        <span class="mr-1" target="_blank">Versión Gesto <span>{{version}}</span></span>
                    </div>
                </form>
            </section>
        </div>
    </div>
</template>
  
<script>
    import cons from '@/const'
    import apiUrls from '@/views/utils/apiUrls'
    import * as util from '@/views/utils/utilsFunctions'
    
    import { CIcon } from '@coreui/vue';
    import { freeSet } from "@coreui/icons";
    import axios from "axios"
    import CargandoInfo from '@/views/utils/CargandoInfo'

    //import bcrypt from 'bcryptjs';

    export default {
        name: 'Login2',
        freeSet,
        components: {
            CargandoInfo
        },
        data() {
            return {
                appName: cons.appName,
                version: cons.version,
                urlBase: cons.port + "://" + cons.ipServer,

                habilitadoRecordar: true,
                habilitadoOlvideContrasenia: true,
                habilitadoRegistrar: true,

                showMensaje : false,

                textosWeb: {
                    "textoRecordar": "Recordar",
                    "msjCambiarPass": "Su contraseña está por defecto, Le recomendamos cambiar la contraseña por seguridad",
                    "msjSolicitandoCambio": ""
                },
                
                tipoMensaje: "danger",
                
                msjRutNoConfig: "",
                showMensajeRutNoConfig: false,
                showMensajeCambioPass: false,
                
                rutRegistrar: null,
                rutValidarRegistrar: false,
                
                solicitudEnviada: false,
                enviandoSolicitud: false,
                
                logueoResp: null,

                ingresarRut: false,

                isLogin: false,
                recordarDatos: false,
                userLogin: {
                    username:"",
                    passw:"",
                    rutEmpresa: null,
                    rutEmpresaValidado: true,
                },
            };
        },
        created() {
            if (localStorage.getItem('rememberMe') === 'true') {
                this.userLogin.username = localStorage.getItem('username') || '';
                this.userLogin.passw = localStorage.getItem('password') || '';
                this.userLogin.rutEmpresa = localStorage.getItem('rutEmpresa') || '';
                this.recordarDatos = true;
            }
        },
        beforeMount() {
            this.validarSesion()
        },
        methods: {
            cambioPortal: function(){
                this.$router.push('login_trabajador')///pages/login
            },
            formatearNombreUsuario: function(){
                this.userLogin.username = util.borrarEspaciosTexto(this.userLogin.username)
            },
            formatearIngresoRut: function(){
                this.userLogin.rutEmpresa = util.formatearRut(this.userLogin.rutEmpresa)//this.formatoRut(this.userLogin.rutEmpresa)//util.formatearRut(this.userLogin.rutEmpresa)
                this.userLogin.rutEmpresaValidado = util.validarRut(this.userLogin.rutEmpresa)
            },
            
            formatearRutRegistrar: function(){
                this.rutRegistrar = util.formatearRut(this.rutRegistrar)
                this.rutValidarRegistrar = util.validarRut(this.rutRegistrar)
            },

            ingresar: function(){
                if(!this.showMensajeCambioPass && !this.showMensajeRutNoConfig){
                    this.iniciarSesion()
                } else{
                    this.loguearUser()
                }
            },
            iniciarSesion: async function(){
                if (this.recordarDatos) {
                    localStorage.setItem('username', this.userLogin.username);
                    localStorage.setItem('password', this.userLogin.passw);
                    localStorage.setItem('rutEmpresa', this.userLogin.rutEmpresa); 
                    localStorage.setItem('rememberMe', true);
                } else{
                    localStorage.removeItem('username', this.userLogin.username);
                    localStorage.removeItem('password', this.userLogin.passw);
                    localStorage.setItem('rutEmpresa', this.userLogin.rutEmpresa);
                    localStorage.removeItem('rememberMe', true);
                }
                this.isLogin = true
                this.showMensaje = false

                var url =  this.urlBase + "/login_gesto/"
                /*let encryptedPassword = await bcrypt.hash(this.userLogin.passw, 10);
                console.log("encryptedPassword", encryptedPassword)
                */
                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        datosLogin: {
                            username: this.userLogin.username,
                            passw: this.userLogin.passw,//encryptedPassword //this.userLogin.passw
                            rutEmpresa: this.userLogin.rutEmpresa
                        }
                    }
                }).then(result => {
                    this.logueoResp = result.data.userLog

                    if(this.logueoResp.rutSinAgregar){
                        this.showMensajeRutNoConfig = true
                        this.textosWeb.msjRutNoConfig = "Su rut no está configurado, Le recomendamos agregarlo, ¿quiere hacerlo?"
                        this.mostrarNotificaciones(2, "Su rut no está configurado, Le recomendamos agregarlo, para hacerlo seleccione la opción en la pantalla.")//Su contraseña está por defecto, Le recomendamos cambiar la contraseña por seguridad,  para hacerlo seleccione la opción en la pantalla.
                    }

                    if(this.logueoResp.pswDefecto){
                        this.showMensajeCambioPass = true
                        this.textosWeb.msjCambiarPass = "Su contraseña está por defecto, Le recomendamos cambiar la contraseña por seguridad, ¿quiere hacerlo?"
                        this.mostrarNotificaciones(2, "Su contraseña está por defecto, Le recomendamos cambiar la contraseña por seguridad, para hacerlo seleccione la opción en la pantalla.")
                        
                    } 
                    if (!this.logueoResp.rutSinAgregar && !this.logueoResp.pswDefecto) {
                        this.loguearUser()
                    }
                    this.isLogin = false

                })
                .catch(error =>{
                    this.isLogin = false
                    let msjError = "Ups, lo sentimos no hemos podido procesar el inicio de Sesión"
                    
                    if (error.response) {
                        // El servidor respondió con un estado diferente de 2xx
                        msjError = `${error.response.data.mensaje}`;
                    } else if (error.request) {
                        // La solicitud fue hecha pero no se recibió respuesta
                        msjError = 'Ups, no se recibe respuesta desde el servidor, si el problema persiste contactar a soporte@vapp.cl, gracias';
                    } else if (error.message === 'Network Error') {
                        // Error de red específico
                        msjError = 'Ups, lo sentimos, pero no tenemos acceso a la url del servidor, si el problema persiste contactar a soporte@vapp.cl, gracias'
                    } else {
                        // Algo pasó al configurar la solicitud que provocó un error
                        msjError = `${error.message}`;
                    }
                    this.mostrarNotificaciones(3, msjError)
                })
                .finally(() => {
                    this.isLogin = false
                });

            },
            loguearUser: function(){
                
                this.isLogin = true
                if(this.ingresarRut){
                    this.registrarRut()
                }

                let cookieUsername = this.$cookie.get('userLogginToken')
                var uslog = this.logueoResp

                if(cookieUsername === null || cookieUsername === ""){

                this.$cookie.set("userLogginToken", JSON.stringify(uslog), { expires: this.logueoResp.fechaExpToken });

                } else {

                if(uslog.tokenUsuario != JSON.parse(cookieUsername).tokenUsuario){
                    this.$cookie.set("userLogginToken", JSON.stringify(uslog), { expires: this.logueoResp.fechaExpToken });
                }

                }

                //this.showMensaje = true
                //this.mensajeLogin = "Bienvenido " + this.logueoResp.nombreUsuario
                //this.tipoMensaje = "success"
                this.mostrarNotificaciones(1, "Bienvenido " + this.logueoResp.nombreUsuario)
                //this.$notification.success("Bienvenido " + this.logueoResp.nombreUsuario, {  timer: 10, position:"bottomRight", showCloseIcn:true});
                cons.rutaRedireccion = this.logueoResp.rutaRedireccion
                
                this.isLogin = false
                this.$router.push(this.logueoResp.rutaRedireccion);

            },
            registrarRut: function(){
                
                
                var url =  this.urlBase + "/reg_rut_usuario"
                axios({
                    url: url,
                    method: 'POST',
                    "data": {
                        "info": {
                            "rut": this.rutRegistrar,
                            "usuario": this.userLogin.username,
                            "idCliente": this.logueoResp.idCliente
                        }
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    },

                    }).then((response) => {
                        this.mostrarNotificaciones(1, response.data)
                    })
                    .finally(() => {
                        //this.mostrarNotificaciones(1, error.response.data)
                    });


            },
            cambiarContraseña: function(){

                this.enviandoSolicitud = true
                this.textosWeb.msjSolicitandoCambio = "Enviando Solicitud"
                var url = this.urlBase + "/solicitar_cambio_pass/"

                axios({
                    method: "GET", 
                    "url": url,
                    "headers": {
                        'Authorization': `${this.logueoResp.tokenUsuario}`
                    }
                }).then(result => {

                    this.textosWeb.msjSolicitandoCambio = "Solicitud enviada, revise su correo."
                    this.mostrarNotificaciones(1, result.data) 
                    //this.$notification.success(result.data, {  timer: 10, position:"bottomRight"});
                    this.solicitudEnviada = true
                    
                }, error => {
                    this.mostrarNotificaciones(3, error.response.data)
                    //this.$notification.error(error.response.data, {  timer: 10, position:"bottomRight"});
                    
                })
                .finally(() => {

                });
            },

            validarRutRegistrar2: function(event){
                var rutCompleto = this.rutRegistrar
                    
                if(rutCompleto == "" || rutCompleto == null){
                this.rutValidarRegistrar = false
                } else{

                rutCompleto = rutCompleto.replace("‐","-");

                if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)){
                    this.rutValidarRegistrar =  false;
                    return
                }

                var tmp = rutCompleto.split('-');
                var digv = tmp[1]; 
                var rut = tmp[0];

                if ( digv == 'K' ) digv = 'k' ;

                this.rutValidarRegistrar = (this.validarDv(rut) == digv )
                }
            },
            goReestablecerPsw: function(){
                this.$router.push('ReestablecerPsw')
            },
            validarSesion: function(){
                let cookieUsername = this.$cookie.get('userLogginToken')

                if(cookieUsername !== null ){
                
                this.$router.push(JSON.parse(cookieUsername).rutaRedireccion);
                }    
            },
            
        },
    }
</script>

<style scoped>
    
    /*@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');*/
    /* Estilos base */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

img {
    max-width: 100%;
    height: auto;
}

/* Estilos de la estructura principal */
.principal {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-image: url("/img/mix.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 20px;
}

section {
    position: relative;
    max-width: 400px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    backdrop-filter: blur(55px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 3rem;
}

h1 {
    font-size: 2rem;
    color: #fff;
    text-align: center;
}

.inputbox {
    position: relative;
    margin: 20px 0;
    width: 100%;
    border-bottom: 2px solid #fff;
}

.inputbox label {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    color: #fff;
    font-size: 0.8rem;
    pointer-events: none;
    transition: all 0.5s ease-in-out;
}

input:focus ~ label, 
input:valid ~ label {
    top: -5px;
}

.inputbox input {
    width: 100%;
    height: 30px;
    background: transparent;
    border: none;
    outline: none;
    font-size: 1rem;
    padding: 0 35px 0 5px;
    color: #fff;
}

.inputbox .c-icon, .inputbox ion-icon {
    position: absolute;
    right: 8px;
    color: #fff;
    font-size: 1.2rem;
    top: 20px;
}

.forget, .msjs {
    margin: 35px 0;
    font-size: 0.75rem;
    color: #fff;
    display: flex;
    justify-content: space-between;
}

.forget label, .msjs label {
    display: flex;
    align-items: center;
}

.forget label input, .msjs label input {
    margin-right: 3px;
}

.forget a, .msjs a {
    color: #fff;
    text-decoration: none;
    font-weight: 600;
}

.forget a:hover, .msjs a:hover {
    text-decoration: underline;
}

button {
    width: 100%;
    height: 40px;
    border-radius: 40px;
    background-color: rgba(255, 255, 255, 1);
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    transition: all 0.4s ease;
}

button:hover {
    background-color: rgba(255, 255, 255, 0.5);
}

.register {
    font-size: 0.9rem;
    color: #fff;
    text-align: center;
    margin: 25px 0 10px;
}

.register p a {
    text-decoration: none;
    color: #fff;
    font-weight: 600;
}

.register p a:hover {
    text-decoration: underline;
}

.version {
    font-size: 0.6rem;
    text-decoration: none;
    color: #fff;
    font-weight: 100;
}

/* Media Queries para pantallas más pequeñas */
@media (max-width: 768px) {
    section {
        padding: 1.5rem 2rem;
    }

    h1 {
        font-size: 1.8rem;
    }

    button {
        height: 35px;
    }
}

@media (max-width: 480px) {
    section {
        padding: 1rem 1.5rem;
    }

    h1 {
        font-size: 1.5rem;
    }

    button {
        height: 30px;
        font-size: 0.9rem;
    }
}
</style>